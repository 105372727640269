// If you want to override variables do it here
@import "variables";

// Import CoreUI styles
@import "~@coreui/coreui-pro/scss/coreui.scss";

// Some temp fixes
@import "fixes";

// If you want to add something do it here
@import "custom";

.nav{
    margin-bottom: 24px !important;
}

.noShow{
    display: none;
}

.form-control-timer{
    width: 100%;
    margin-bottom: 0px !important;
}
.form-group-timer{
    padding: .5rem;
    margin-bottom: 0 !important;
    align-items: center;
    justify-content: center;
    display: flex;
}
.rc-time-picker-input {
    height: 35px !important;
    font-size: 14.5px !important;
    padding-left: 13px !important;
}
.rc-time-picker-clear {
    top: 6px !important;
}